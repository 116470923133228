import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getRGBAColorWithOpacity } from '../../utils/helpers'
import { CHART_TITLES_IG31_API, ANTIGEN_LOOKUP_API } from '../../utils/const'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
//import { wrangle31Data } from '../../utils/helpers'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  margin: 3rem -1.5rem;
`

const MiniChartContainer = styled.div`
  margin: 1rem 1.5rem 6rem 1.5rem;
  min-width: ${({ minWidth }) => minWidth};
  ${Mq.xs} {
    min-width: 300px;
  }
`
const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${({ color }) => `var(--${color})`};
`
const MiniChart = styled.figure`
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  //background-color: pink;
`

const BarCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80px;
  height: 100%;
  background-color: ${({ color }) => color};
  margin: 0 10px;
  :nth-child(1) {
    margin-left: 0;
  }
  justify-content: flex-end;
  align-items: center;
`
const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  opacity: 1;
  color: var(--white);
  font-weight: bold;
  //padding-top: 0.5rem;
`

const BarPercentage = styled.div`
  position: relative;
  top: ${({ value }) => (value >= 8 ? '16px' : '-30px')};
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  margin: 0 -0.5rem;
  text-align: center;
  line-height: 1.2;
`
const Line = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  border-top: ${({ color }) => `3px solid var(--${color})`};
  position: absolute;
  left: 0;
  bottom: 0;
`
const Label = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${({ color }) => `var(--${color})`};
  width: 100%;

  text-align: right;
  position: absolute;
  top: ${({ position }) => position};
`

export const ANTIGEN_COLORS_API = {
  DTP3: 'brightBlue',
  PCV3: 'purple',
  MCV2: 'rose',
  HPVc: 'aqua'
}
const IG31Chart = ({ regions, title }) => {
  const { t } = useTranslation()

  const formattedData = regions?.reduce((acc, d) => {
    if (acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]]) {
      acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]].push({
        year: d.YEAR,
        value: d.VALUE_NUM
      })
    } else {
      acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]] = [
        {
          year: d.YEAR,
          value: d.VALUE_NUM
        }
      ]
    }
    return acc
  }, {})
  //find highest number of years of data in any chart
  const findLongestArray = (data) => {
    let max = 0
    Object.entries(formattedData).forEach(([key, value]) => {
      if (value.length > max) max = value.length
    })
    return max
  }

  const calcMinWidth = () => {
    //return '400px'
    return Math.max(findLongestArray(formattedData) * 100 + 100, 388) + 'px'
  }
  const order = ['DTP3', 'PCV3', 'MCV2', 'HPVc']
  const BASELINE_YEAR = 2019
  return (
    <Container>
      {order.map((key) => (
        <MiniChartContainer minWidth={calcMinWidth()} key={key}>
          <ChartTitle color={ANTIGEN_COLORS_API[key]}>
            {t(CHART_TITLES_IG31_API[key])} &#8212; {t(key)}
          </ChartTitle>
          <MiniChart
            role="img"
            alt={`${t(
              'Bar chart showing percentage of vaccine coverage by year for'
            )} ${t(CHART_TITLES_IG31_API[key])} — ${t(key)}`}
          >
            {formattedData[key].map((d, i) => (
              <BarCol
                key={key + d.value * i}
                color={getRGBAColorWithOpacity(ANTIGEN_COLORS_API[key], 0.3)}
              >
                <Bar
                  height={`${d.value > 0 ? d.value : 0}%`}
                  color={ANTIGEN_COLORS_API[key]}
                >
                  <BarPercentage value={d.value}>
                    {`${(d.value > 0 ? d.value : 0).toFixed()}%`}
                  </BarPercentage>
                </Bar>
                <BarLabelContainer>
                  <BarLabel
                    weight={d.year === BASELINE_YEAR ? 'bold' : 'normal'}
                  >
                    {d.year === BASELINE_YEAR ? t('Baseline') : d.year}
                  </BarLabel>
                  {/* <BarLabel weight={'bold'}>{t('Baseline')}</BarLabel>} */}
                </BarLabelContainer>
              </BarCol>
            ))}
            <Line height={'90%'} color={ANTIGEN_COLORS_API[key]}>
              <Label color={ANTIGEN_COLORS_API[key]} position={'-30px'}>
                {t('2030 target')}
              </Label>
              <Label color={ANTIGEN_COLORS_API[key]} position={'0px'}>
                90%
              </Label>
            </Line>
          </MiniChart>
        </MiniChartContainer>
      ))}
    </Container>
  )
}

export default IG31Chart
